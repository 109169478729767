// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, DeviceEventEmitter, TouchableOpacity } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, CustomTextField, CustomTextFieldWithScanner, DangerButton, Heading, PrimaryButton, Row, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../../../mycomponents/CRUD/LayoutScreen"
import FormScreen from "../../../../../mycomponents/CRUD/FormScreen"
import { Icon } from "react-native-eva-icons"
import { CustomSelectItemForContacts, TransactionPDFPreview, invoiceItemAdjustment } from "../../../../../models/accountancy/transaction"
import { create } from 'zustand'
import { FlashList } from "@shopify/flash-list";
import { GlobalModalStore } from "../../../../../mycomponents/state/ModalStore"
import { App } from 'antd';
import * as Sharing from 'expo-sharing';
import { GlobalMasterStore } from "../../../../../mycomponents/state/PermissionStore"
import * as Print from 'expo-print';
import Svg, { Circle, Path, Rect } from "react-native-svg"
import moment from "moment"
import { InKitchenComponent } from "./TableScreen"
import { MainScannerScreenComponent } from "../../../../../mycomponents/CRUD/ListingScreen"

const useFormObject = create((set, get) => ({
    formObject: {},
    setFormObject: (keyValue) => set((state) => ({ formObject: { ...state.formObject, ...keyValue } })),
    setFormObjectArray: (array_key, array_index, field_key, value) => set((state) => {
        if (typeof field_key === 'object') {
            const newFormObject = { ...state.formObject };
            newFormObject[array_key][array_index] = { ...newFormObject[array_key][array_index], ...field_key };
            return { formObject: newFormObject };
        } else {
            const newFormObject = { ...state.formObject };
            // newFormObject[array_key][array_index][field_key] = value;
            const newArray = newFormObject[array_key];
            newArray[array_index][field_key] = value;
            newFormObject[array_key] = newArray;
            return { formObject: newFormObject };
        }
    }),
    getFormObjectArrayValue: (array_key, array_index, field_key) => get().formObject[array_key][array_index][field_key],
    getFormObject: () => get().formObject,
}))

export const ErrorMessage = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={202}
        height={135}
        fill="none"
        {...props}
    >
        <Circle cx={100} cy={74} r={61} fill="#F1F2F8" />
        <Path
            fill="#fff"
            d="M53.802 108.728v-48.97l13.591-25.145a2 2 0 0 1 1.76-1.048H129.8c.72 0 1.384.386 1.739 1.012l14.292 25.18v48.971a2 2 0 0 1-2 2h-88.03a2 2 0 0 1-2-2Z"
        />
        <Path
            stroke="#D9DBE8"
            d="M53.802 59.758v48.97a2 2 0 0 0 2 2h88.03a2 2 0 0 0 2-2v-48.97m-92.03 0h25.562a2 2 0 0 1 1.849 1.238l3.17 7.7a5 5 0 0 0 4.624 3.096h21.795c1.937 0 3.7-1.118 4.524-2.87l3.772-8.016a2 2 0 0 1 1.81-1.148h24.924m-92.03 0 13.591-25.145a2 2 0 0 1 1.76-1.048H129.8c.72 0 1.384.386 1.739 1.012l14.292 25.18"
        />
        <Rect
            width={12.743}
            height={4.248}
            x={93.445}
            y={97.277}
            fill="#D9DBE8"
            rx={2.124}
        />
        <Rect
            width={5.663}
            height={7.079}
            x={83.535}
            y={80.287}
            fill="#D9DBE8"
            rx={2.832}
        />
        <Rect
            width={5.663}
            height={7.079}
            x={110.436}
            y={80.287}
            fill="#D9DBE8"
            rx={2.832}
        />
        <Path
            fill="#fff"
            stroke="#D9DBE8"
            d="M109.936 18.698c0 6.505 4.174 12.15 10.409 15.363l-.387 6.192c-.068 1.09 1.231 1.703 2.03.958l5.158-4.814a27.5 27.5 0 0 0 5.235.499c12.291 0 22.446-8.052 22.446-18.198S144.672.5 132.381.5c-12.29 0-22.445 8.052-22.445 18.198Z"
        />
        <Path
            fill="#fff"
            d="M154.327 18.698c0 9.774-9.826 17.698-21.946 17.698-12.12 0-21.945-7.924-21.945-17.698C110.436 8.924 120.261 1 132.381 1c12.12 0 21.946 7.924 21.946 17.698Z"
        />
        <Path
            fill="#fff"
            d="m120.457 40.284.55-8.786a.707.707 0 0 1 .806-.657l7.688 1.098a.708.708 0 0 1 .383 1.219l-8.237 7.688a.708.708 0 0 1-1.19-.562Z"
        />
        <Path
            fill="#D9DBE8"
            d="m129.035 9.36-4.552.317a.5.5 0 0 0-.458.581l2.284 13.74a.5.5 0 0 0 .528.418l1.918-.134a.5.5 0 0 0 .465-.487l.35-13.924a.5.5 0 0 0-.535-.512ZM141.114 10.533l-4.469-.926a.5.5 0 0 0-.598.435l-1.522 13.845a.5.5 0 0 0 .396.545l1.882.39a.5.5 0 0 0 .579-.342l4.108-13.31a.5.5 0 0 0-.376-.637ZM126.645 28.186l-.128-1.827a.5.5 0 0 1 .464-.534l1.827-.128a.5.5 0 0 1 .534.464l.128 1.827a.5.5 0 0 1-.464.534l-1.827.128a.5.5 0 0 1-.534-.464ZM133.715 28.009l.371-1.794a.5.5 0 0 1 .591-.388l1.794.372a.5.5 0 0 1 .388.591l-.372 1.794a.5.5 0 0 1-.591.388l-1.793-.372a.499.499 0 0 1-.388-.591Z"
        />
        <Circle cx={25.485} cy={110.02} r={8.495} fill="#F1F2F8" />
        <Circle
            cx={179.074}
            cy={41.322}
            r={8.495}
            fill="#F1F2F8"
            transform="rotate(132.827 179.074 41.322)"
        />
        <Circle cx={4.248} cy={40.644} r={4.248} fill="#F1F2F8" />
        <Circle cx={12.743} cy={125.594} r={4.248} fill="#F1F2F8" />
        <Circle
            cx={195.892}
            cy={35.675}
            r={4.248}
            fill="#F1F2F8"
            transform="rotate(132.827 195.892 35.675)"
        />
    </Svg>
)

const InKitchenScreen = observer(function InKitchenScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    let queryParams = {};

    if (Platform.OS === 'web') {
        const params = window.location.search.substring(1).split('&')
        params.map((param) => {
            const [key, value] = param.split('=')
            if (key) {
                queryParams[key] = value;
            }
        })
    } else {
        queryParams = props.route.params || {};
    }

    const goBack = () => {
        if (Platform.OS === 'web') {
            window.history.back();
        } else {
            navigation.goBack();
        }
    }

    const [search, setSearch] = useState("");
    const [counter, setCounter] = useState(null);
    const [methods, setMethods] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [cashAccountId, setcashAccountId] = useState('');

    const [selectedMethod, setSelectedMethod] = useState("1");
    const [locations, setLocations] = useState([]);

    const [saving, setSaving] = useState(false);

    const [tables, setTables] = useState([]);
    const [areas, setAreas] = useState([]);
    const [refreshCount, setRefreshCount] = useState(0);
    const debounceRef = React.useRef(null);

    const [response, setResponse] = useState(null);

    const formObject = useFormObject((state) => state.formObject);
    const getFormObject = useFormObject((state) => state.getFormObject);
    const setArrValue = useFormObject((state) => state.setFormObjectArray);
    const setFormObject = useFormObject((state) => state.setFormObject);
    const employee = GlobalMasterStore((state) => state.employee);
    const moduleName = "Bills";

    const { fields, dataTabs, preferencesFields, ExtraSaveComponent } = api.getForm(moduleName);

    let params = {};

    const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
    const globalData = GlobalMasterStore((state) => state.globalData);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const contact_id = GlobalMasterStore((state) => state.globalData.contact_id);

    const [takeAwayInvoices, setTakeAwayInvoices] = useState([]);
    const [inKitchenItems, setInKitchenItems] = useState([]);
    const [filteredInKitchenItems, setFilteredInKitchenItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [showScanner, setShowScanner] = useState(false);
    const [currentBill, setCurrentBill] = useState(null);
    const [products, setProducts] = useState([]);

    const getAllBills = async (values = null) => {
        const response = await api.get("Bills", {
            "page": 1,
            "limit": 1000,
            "sortColumn": "",
            "sortDirection": "",
            "filters": [],
            "tabFilter": {
                contact_id: contact_id,
                balance: { $gt: 0 },
            },
            "search": "",
            "groupBy": null
        });

        console.log("response", response);

        const bill = response.data.data[0];
        if (bill) {
            setCurrentBill(bill);
        } else {
            setCurrentBill(null);
        }
    }

    useEffect(() => {
        setInterval(() => {
            getAllBills();
        }, 10000);

        getAllBills();

        return () => {
            clearInterval();
        }
    }, []);

    const isSerialNumberAvailable = async () => {
    }

    useEffect(() => {
        isSerialNumberAvailable();
    }, [search]);


    const OpenModalCustom = ({ newModule, field_key, array = false, params = null }) => {
        DeviceEventEmitter.addListener("reloadData" + "POS", (event) => {
            if (!event) {
                DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
                if (Platform.OS == 'web') {
                    setOpenModal({ [moduleName]: null })
                } else {
                    //navigation.pop();
                }
                return;
            }

            const newObj = getFormObject();

            if (event?.deleted) {
                DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
                let object = { ...newObj };

                if (event?.id) {
                    if (array) {
                        const oldValues = object[field_key] ? object[field_key] : [];
                        object[field_key] = oldValues.filter((id) => id != event?.id);
                    } else {
                        object[field_key] = null;
                    }
                }

                getModuleCreateData({ ...object, disable_auto_line_item: true });

                if (Platform.OS == 'web') {
                    setOpenModal({
                        [newModule]: null
                    })
                } else {
                    //goBack();
                }
                return;
            }

            let object = { ...newObj };

            if (array) {
                const oldValues = object[field_key] ? object[field_key] : [];
                object[field_key] = [...new Set([...oldValues, event._id])]
            } else {
                object[field_key] = event._id;
            }

            getModuleCreateData({ ...object, disable_auto_line_item: true });

            if (Platform.OS == 'web') {
                setOpenModal({
                    [newModule]: null
                })
            } else {
                //goBack();
            }

            DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
        })

        if (Platform.OS == 'web') {
            let data = {
                [newModule]: {
                    isVisible: true,
                    moduleName: [newModule],
                    viewOnly: false,
                    afterSaveEvent: 'reloadData' + "POS",
                    ignoreQueryParams: true,
                    ...(params ? params : {})
                }
            };

            setOpenModal(data)
        } else {
            navigationFn('New', {
                moduleName: newModule,
                afterSaveEvent: 'reloadData' + "POS",
                ...(params ? params : {})
            })
        }
    }


    const getModuleCreateData = async (values = null, id = null) => {
        params = { ...params, id: id };
        let response = await api.getCreateData(moduleName, params);

        if (!response) {
            return;
        }

        setResponse(response);

        setFormObject({
            contact_id: contact_id,
        });

        setProducts(response?.Items || []);

        if (params?.id) {
            const id = params?.id;
            const cloningModuleName = params?.cloningModuleName ? params?.cloningModuleName : moduleName;

            const { data } = await api.getSingle(cloningModuleName, { _id: id, converting: cloningModuleName == moduleName ? false : true });
            let form = api.objectToForm(data, fields(response, params?.viewOnly, moduleName, OpenModalCustom, data, false, globalData, setGlobalData));

            if (values) {
                form = api.objectToForm(values, fields(response, params?.viewOnly, moduleName, OpenModalCustom, data));
            }

            //const object = api.formToObject(form);
            const finalObj = { ...data };

            setFormObject(finalObj);

            const filteredProducts = response?.Items.map((product) => {
                const orderItem = finalObj.line_items.find((orderItem) => orderItem.item_id == product._id);

                if (orderItem) {
                    return {
                        ...product,
                        quantity: orderItem.quantity,
                    }
                }

                return product
            })

            setProducts(filteredProducts)
        } else {
            let form = null;

            if (params?.is_default_values) {
                const data = { ...params };
                delete data.is_default_values;
                delete data.moduleName;
                form = api.objectToForm(data, fields(response, params?.viewOnly, moduleName, OpenModalCustom, data));
            } else {
                form = fields(response, params?.viewOnly, moduleName, OpenModalCustom, null);
            }

            if (values) {
                form = api.objectToForm(values, fields(response, params?.viewOnly, moduleName, OpenModalCustom, values));
            }

            const newObject = api.formToObject(form);
            newObject.table_id = values.table_id;
            newObject.status = "Received";

            newObject.contact_id = contact_id;

            let obj = { ...newObject };
            setFormObject(obj);
        }
    }

    useEffect(() => {
        if (currentBill?._id) {
            getModuleCreateData({ disable_auto_line_item: true }, currentBill?._id);
        } else {
            getModuleCreateData({ disable_auto_line_item: true });
        }

        return () => {
            DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
            setFormObject({});
        }
    }, [currentBill]);

    useEffect(() => {
        //getAllBills();
        if (response) {
            invoiceItemAdjustment(formObject, setFormObject, setArrValue, response);
            console.log("formObject", formObject);
        }
    }, [formObject.line_items]);

    // split text using || and return the first part that will be the sku code
    // use pos's add product function to add the product to the bill if item added show success else show invalid qr code
    // if current bill is not available, create a new bill or else use the current bill

    function addProduct(item, serial_number, qt = 0) {
        let found = false
        const obj = getFormObject()
        if (obj && obj.line_items) {
            let item_quantity = 0

            let newOrderItems = obj.line_items.map((orderItem) => {
                if (orderItem.item_id == item._id) {
                    found = true

                    if (serial_number && item.track_serial_number && serial_number.length > 0) {
                        let serial_numbers = [...(orderItem.serial_numbers || []), serial_number];
                        serial_numbers = [...new Set(serial_numbers)];

                        item_quantity = serial_numbers.length;

                        return {
                            ...orderItem,
                            quantity: serial_numbers.length,
                            item_price: orderItem.item_price,
                            serial_numbers: serial_numbers,
                        }
                    } else {
                        item_quantity = qt ? +qt : (orderItem.quantity + 1);


                        return {
                            ...orderItem,
                            quantity: item_quantity,
                            item_price: orderItem.item_price,
                        }
                    }
                }

                return orderItem
            })

            if (!found) {
                item_quantity = qt ? +qt : 1

                const TaxGroups = response.TaxGroups || []
                const tax_group = TaxGroups.find((tax_group) => tax_group._id === item.tax_group_id)

                console.log("item", item);
                console.log("purchase_rate", item.purchase_rate);

                const itemMap = {
                    "item": item,
                    "item_id": item._id,
                    "item_name": item.name,
                    "hsn_or_sac": item.hsn_or_sac,
                    "description": item.description,
                    "account_id": item.purchase_account_id,
                    "track_inventory": item.track_inventory,
                    "track_serial_number": item.track_serial_number,
                    "track_batch_number": item.track_batch_number,
                    "usage_unit": item.usage_unit,
                    "tax_group_id": item.tax_group_id,
                    "tax_group": tax_group,
                    "quantity": 1,
                    "item_price": item.purchase_rate || 0,
                    "item_gross_total": item.purchase_rate || 0,
                    "item_total": item.purchase_rate || 0,
                    "discount_percentage": 0,
                    "discount_amount": 0,
                    "status": moduleName == 'Invoices' ? 'Pending' : moduleName == 'Bills' ? 'Pending' : 'Pending',
                    "serial_numbers": serial_number ? [serial_number] : [],
                    "add_ons_selected": [],
                }

                newOrderItems.push(itemMap)
            }

            setFormObject({
                line_items: newOrderItems,
            })
        }
    }

    const checkIfSerialNumberExistsAndActive = async (serial_number) => {
        let response = await api.customRoute("SerialNumbers/action", {
            action: 'checkIfSerialNumberExistsAndActive',
            serial_number: serial_number
        });

        return response;
    }

    const markSerialNumberAsUsed = async (serial_number) => {
        let response = await api.customRoute("SerialNumbers/action", {
            action: 'markSerialNumberAsUsed',
            serial_number: serial_number
        });

        return response;
    }

    async function saveFn({ print = false, goBackAfterSave = false, clearOrder = false, delivered = true, serial_number = "" }) {
        const newFormObject = { ...getFormObject() };

        if (!newFormObject.line_items || newFormObject.line_items.length == 0) {
            if (Platform.OS === 'web') {
                message.info("Please add items to the order");
            } else {
                Alert.alert("Please add items to the order");
            }
            return;
        }

        newFormObject.generatePdf = false;

        setSaving(true);

        let res = null;

        let queryParams = {};

        if (Platform.OS === 'web') {
            const params = window.location.search.substring(1).split('&')
            params.map((param) => {
                const [key, value] = param.split('=')
                if (key) {
                    queryParams[key] = value;
                }
            })
        } else {
            queryParams = props.route.params || {};
        }

        if (delivered) {
            if (newFormObject.table_id) {
                newFormObject.status = "Delivered";
            } else {
                newFormObject.status = newFormObject.status == "Delivered" ? "Delivered" : "Pending";
            }
        }

        if (currentBill?._id) {
            res = await api.update(moduleName, newFormObject, currentBill?._id);
        } else {
            res = await api.save(moduleName, newFormObject);
        }

        if (res.message) {
            setSaving(false);

            if (res.success) {
                if (Platform.OS === 'web') {
                    message.info("Coupon redeemed successfully");
                } else {
                    Alert.alert("Coupon redeemed successfully");
                }


                setFormObject({
                    _id: res.data._id,
                });

                getModuleCreateData({ disable_auto_line_item: true }, res.data._id);

                markSerialNumberAsUsed(serial_number);
                getAllBills();
            } else {
                if (Platform.OS === 'web') {
                    message.info(res.message);
                } else {
                    Alert.alert(res.message);
                }
            }
        } else {
            setSaving(false);
            if (Platform.OS === 'web') {
                message.error("Something went wrong!");
            } else {
                Alert.alert("Something went wrong!");
            }
        }
    }



    return (
        <View flex>
            <View backgroundColor="#f9f9f9b5"
                style={{
                    padding: Platform.OS == 'web' ? 20 : 6
                }}
            >
                <Row>
                    <Col width={Platform.OS == 'web' ? "100%" : '100%'} >
                        <Card style={{ width: '100%', height: Dimensions.get('window').height - 80 }}>
                            <ScrollView style={{
                                height: Dimensions.get('window').height - 200,
                                minHeight: Dimensions.get('window').height - 200,
                            }}>
                                <Text h1 margin-10 marginB-0 center>Current Balance - Rs. {currentBill?.balance || 0}</Text>

                                <View padding-10>
                                    <SecondaryButton label="Scan Now" onPress={async () => {
                                        setShowScanner(true);

                                        // let data = "BUTTONROSE-8108@@11721825273224";

                                        const sku = data.split("@@")[0];
                                        const res = await checkIfSerialNumberExistsAndActive(data);

                                        if (res) {
                                            const product = products.find((product) => product.sku == sku);

                                            if (!product) {
                                                if (Platform.OS === 'web') {
                                                    message.info("Invalid QR Code");
                                                } else {
                                                    Alert.alert("Invalid QR Code");
                                                }
                                                return;
                                            }

                                            addProduct(product);

                                            setTimeout(() => {
                                                saveFn({ serial_number: data });
                                            }, 1000);
                                        } else {
                                            if (Platform.OS === 'web') {
                                                message.info("Invalid QR Code");
                                            } else {
                                                Alert.alert("Invalid QR Code");
                                            }
                                        }
                                    }} />
                                </View>


                                <CustomModal title="Scan" visible={showScanner} onClose={() => {
                                    setShowScanner(false);
                                }}>
                                    <MainScannerScreenComponent onClose={async (data) => {
                                        setShowScanner(false);
                                        const sku = data.split("@@")[0];
                                        const res = await checkIfSerialNumberExistsAndActive(data);

                                        if (res) {
                                            const product = products.find((product) => product.sku == sku);

                                            if (!product) {
                                                if (Platform.OS === 'web') {
                                                    message.info("Invalid QR Code");
                                                } else {
                                                    Alert.alert("Invalid QR Code");
                                                }
                                                return;
                                            }

                                            addProduct(product);

                                            setTimeout(() => {
                                                saveFn({ serial_number: data });
                                            }, 1000);
                                        } else {
                                            if (Platform.OS === 'web') {
                                                message.info("Invalid QR Code");
                                            } else {
                                                Alert.alert("Invalid QR Code");
                                            }
                                        }
                                    }} />
                                </CustomModal>
                            </ScrollView>
                        </Card>
                    </Col>
                </Row>
            </View>
        </View >
    )
})

export default InKitchenScreen