// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, DeviceEventEmitter, TouchableOpacity } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController, KeyboardAwareScrollView } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomCheckbox, CustomModal, CustomNumberInput, CustomSelect, CustomSwitch, CustomTextField, CustomTextFieldWithScanner, DangerButton, Heading, Label, PrimaryButton, Row, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../../../mycomponents/CRUD/LayoutScreen"
import FormScreen from "../../../../../mycomponents/CRUD/FormScreen"
import { Icon } from "react-native-eva-icons"
import { CustomSelectItemForContacts, RND, TransactionPDFPreview, invoiceItemAdjustment } from "../../../../../models/accountancy/transaction"
import { create } from 'zustand'
import { FlashList } from "@shopify/flash-list";
import { GlobalModalStore } from "../../../../../mycomponents/state/ModalStore"
import { App } from 'antd';
import * as Sharing from 'expo-sharing';
import { GlobalMasterStore } from "../../../../../mycomponents/state/PermissionStore"
import * as Print from 'expo-print';
import moment from "moment"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { MainScannerScreenComponent } from "../../../../../mycomponents/CRUD/ListingScreen"

const useFormObject = create((set, get) => ({
    formObject: {},
    setFormObject: (keyValue) => set((state) => ({ formObject: { ...state.formObject, ...keyValue } })),
    setFormObjectArray: (array_key, array_index, field_key, value) => set((state) => {
        if (typeof field_key === 'object') {
            const newFormObject = { ...state.formObject };
            newFormObject[array_key][array_index] = { ...newFormObject[array_key][array_index], ...field_key };
            return { formObject: newFormObject };
        } else {
            const newFormObject = { ...state.formObject };
            // newFormObject[array_key][array_index][field_key] = value;
            const newArray = newFormObject[array_key];
            newArray[array_index][field_key] = value;
            newFormObject[array_key] = newArray;
            return { formObject: newFormObject };
        }
    }),
    getFormObjectArrayValue: (array_key, array_index, field_key) => get().formObject[array_key][array_index][field_key],
    getFormObject: () => get().formObject,
}))

const PosScreen = observer(function PosScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    let view = false;

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    const goBack = () => {
        if (Platform.OS === 'web') {
            window.history.back();
        } else {
            navigation.goBack();
        }
    }

    const [search, setSearch] = useState("");
    const [showScanner, setShowScanner] = useState(false);
    const [counter, setCounter] = useState(null);
    const [categories, setCategories] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [cashAccountId, setcashAccountId] = useState('');

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [locations, setLocations] = useState([]);

    const [saving, setSaving] = useState(false);

    const [products, setProducts] = useState([]);
    const [tables, setTables] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [sendInKot, setSendInKot] = useState(true);
    const debounceRef = React.useRef(null);
    const [invoiceId, setInvoiceId] = useState(null);

    const filteringFn = () => {
        let filteredProductsNew = products.filter((product) => {
            const searchText = (search || "").toLowerCase();

            const serialNumberSearch = [...(product.serial_numbers || [])]

            if (serialNumberSearch.length > 0) {
                const serial_number_index = serialNumberSearch.findIndex((serial_number) => serial_number.toLowerCase() == searchText);

                if (serial_number_index > -1) {
                    let actual_serial_number = serialNumberSearch[serial_number_index];

                    addProduct(product, actual_serial_number);
                    setSearch("");
                }
            }

            if (searchText == "") {
                if (selectedCategory == null) {
                    return true;
                }

                return product.item_category_id == selectedCategory;
            } else {
                if (selectedCategory == null) {
                    const searchableFields = [
                        product.name.toLowerCase(),
                        product.sku.toLowerCase(),
                        ...serialNumberSearch
                    ];

                    const val = searchableFields.some((field) => {
                        return field.includes(searchText);
                    });

                    return val;
                } else {
                    if (product.item_category_id == selectedCategory) {
                        const searchableFields = [
                            product.name.toLowerCase(),
                        ];

                        const val = searchableFields.some((field) => {
                            return field.includes(searchText);
                        });

                        return val;
                    } else {
                        return false;
                    }
                }
            }
        });

        setFilteredProducts(filteredProductsNew);
    }

    useEffect(() => {
        if (products.length > 0) {
            if (debounceRef.current) {
                clearTimeout(debounceRef.current);
            }

            debounceRef.current = setTimeout(() => {
                debounceRef.current = null;
                filteringFn();
            }, 500);
        }
    }, [search, selectedCategory]);

    useEffect(() => {
        if (products.length > 0) {
            filteringFn();
        }
    }, [products]);

    const [orderItems, setOrderItems] = useState([]);
    const [paymentModalVisible, setPaymentModalVisible] = useState(false);
    const [response, setResponse] = useState(null);

    const [cash_received, setCashReceived] = useState(0);
    const [showSummary, setShowSummary] = useState(false);
    const [showPDF, setShowPDF] = useState(false);
    const [pdf_url, setPdfUrl] = useState(null);
    const [transaction, setTransaction] = useState(null);

    const formObject = useFormObject((state) => state.formObject);
    const getFormObject = useFormObject((state) => state.getFormObject);
    const setArrValue = useFormObject((state) => state.setFormObjectArray);
    const setFormObject = useFormObject((state) => state.setFormObject);

    const moduleName = "Invoices";
    const { fields, dataTabs, preferencesFields, ExtraSaveComponent } = api.getForm(moduleName);
    let params = {};

    let queryParams = {};

    if (Platform.OS === 'web') {
        const params = window.location.search.substring(1).split('&')
        params.map((param) => {
            const [key, value] = param.split('=')
            if (key) {
                queryParams[key] = value;
            }
        })
    } else {
        queryParams = props.route.params || {};
    }

    const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
    const globalData = GlobalMasterStore((state) => state.globalData);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);
    const [reloadPage, setReloadPage] = useState(0);
    const [showTakeAwayQuantityModal, setShowTakeAwayQuantityModal] = useState(null);
    const [showEditItemModal, setShowEditItemModal] = useState(null);
    const [showItemNotesModal, setShowItemNotesModal] = useState(null);
    const [showAddOnsModal, setShowAddOnsModal] = useState(null);
    const [takeAwayQuantity, setTakeAwayQuantity] = useState(0);

    const OpenModalCustom = ({ newModule, field_key, array = false, params = null }) => {
        DeviceEventEmitter.addListener("reloadData" + "POS", (event) => {
            if (!event) {
                DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
                if (Platform.OS == 'web') {
                    setOpenModal({ [moduleName]: null })
                } else {
                    //navigation.pop();
                }
                return;
            }

            const newObj = getFormObject();

            if (event?.deleted) {
                DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
                let object = { ...newObj };

                if (event?.id) {
                    if (array) {
                        const oldValues = object[field_key] ? object[field_key] : [];
                        object[field_key] = oldValues.filter((id) => id != event?.id);
                    } else {
                        object[field_key] = null;
                    }
                }

                getModuleCreateData({ ...object, disable_auto_line_item: true });

                if (Platform.OS == 'web') {
                    setOpenModal({
                        [newModule]: null
                    })
                } else {
                    //goBack();
                }
                return;
            }

            let object = { ...newObj };

            if (array) {
                const oldValues = object[field_key] ? object[field_key] : [];
                object[field_key] = [...new Set([...oldValues, event._id])]
            } else {
                object[field_key] = event._id;
            }

            getModuleCreateData({ ...object, disable_auto_line_item: true });

            if (Platform.OS == 'web') {
                setOpenModal({
                    [newModule]: null
                })
            } else {
                //goBack();
            }

            DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
        })

        if (Platform.OS == 'web') {
            let data = {
                [newModule]: {
                    isVisible: true,
                    moduleName: [newModule],
                    viewOnly: false,
                    afterSaveEvent: 'reloadData' + "POS",
                    ignoreQueryParams: true,
                    ...(params ? params : {})
                }
            };

            setOpenModal(data)
        } else {
            navigationFn('New', {
                moduleName: newModule,
                afterSaveEvent: 'reloadData' + "POS",
                ...(params ? params : {})
            })
        }
    }

    const getModuleCreateData = async (values = null, id = null) => {
        params = { ...params, id: id };
        let response = await api.getCreateData(moduleName, params);

        if (!response) {
            return;
        }

        response.Items = response.Items.filter((item) => {
            return item.product_type != "Assets" && item.product_type != "Raw Material" && item.product_type != "Scrap Material";
        });

        setResponse(response);

        const retailCustomer = response?.Contacts.find((contact) => contact.label == "WALK-IN");

        if (retailCustomer) {
            setFormObject({
                contact_id: retailCustomer._id,
            });
        }

        setProducts(response?.Items || []);
        setTables(response?.Tables || []);
        const tempBankAccounts = response?.Accounts.filter((account) => account.type == "Cash") || [];
        const index = tempBankAccounts.findIndex((account) => account.name == "Cash");

        if (index > -1) {
            setcashAccountId(tempBankAccounts[index]._id);
            tempBankAccounts.splice(index, 1);
        }

        setBankAccounts(tempBankAccounts)

        const categories = response?.Items?.map((item) => {
            return item.item_category;
        });

        const uniqueCategories = [...categories].filter((category) => {
            return category != null;
        }).filter((category, index, self) =>
            index === self.findIndex((t) => (
                t._id === category._id
            ))
        );

        setCategories(uniqueCategories);

        setLocations(response.Locations);

        if (params?.id) {
            const id = params?.id;
            const cloningModuleName = params?.cloningModuleName ? params?.cloningModuleName : moduleName;

            const { data } = await api.getSingle(cloningModuleName, { _id: id, converting: cloningModuleName == moduleName ? false : true });
            let form = api.objectToForm(data, fields(response, params?.viewOnly, moduleName, OpenModalCustom, data, false, globalData, setGlobalData));

            if (values) {
                form = api.objectToForm(values, fields(response, params?.viewOnly, moduleName, OpenModalCustom, data));
            }

            //const object = api.formToObject(form);
            const finalObj = { ...data };

            setFormObject(finalObj);

            const filteredProducts = response?.Items.map((product) => {
                const orderItem = finalObj.line_items.find((orderItem) => orderItem.item_id == product._id);

                if (orderItem) {
                    return {
                        ...product,
                        quantity: orderItem.quantity,
                    }
                }

                return product
            })

            setProducts(filteredProducts)

            setReloadPage(reloadPage + 1);
        } else {
            let form = null;

            if (params?.is_default_values) {
                const data = { ...params };
                delete data.is_default_values;
                delete data.moduleName;
                form = api.objectToForm(data, fields(response, params?.viewOnly, moduleName, OpenModalCustom, data));
            } else {
                form = fields(response, params?.viewOnly, moduleName, OpenModalCustom, null);
            }

            if (values) {
                form = api.objectToForm(values, fields(response, params?.viewOnly, moduleName, OpenModalCustom, values));
            }

            const newObject = api.formToObject(form);
            newObject.table_id = values.table_id;
            newObject.status = "Pending";

            newObject.contact_id = retailCustomer?._id;

            let obj = { ...newObject };

            if (retailCustomer) {
                obj = {
                    ...newObject,
                    contact: retailCustomer,
                    gst_treatment: retailCustomer.gst_treatment,
                    gst_number: '',
                }
            }

            setFormObject(obj);
        }
    }

    useEffect(() => {
        if (queryParams.invoice_id) {
            getModuleCreateData({ disable_auto_line_item: true }, queryParams.invoice_id);
        } else {
            getModuleCreateData({ disable_auto_line_item: true, table_id: queryParams.table_id });
        }

        return () => {
            DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
            setFormObject({});
        }
    }, []);


    useEffect(() => {
        if (response) {
            invoiceItemAdjustment(formObject, setFormObject, setArrValue, response);
        }
    }, [formObject.line_items]);

    const actualCash = (+cash_received) - ((+cash_received + bankAccounts.reduce((a, b) => a + (+(b.amount_received || 0)), 0)) > formObject.total ? (+cash_received + bankAccounts.reduce((a, b) => a + (+(b.amount_received || 0)), 0)) - +formObject.total : 0)

    const Summary = <Col width={Platform.OS == 'web' ? "25%" : '100%'}
        style={Platform.OS == 'web' ? {} : {
            marginLeft: -20,
            marginTop: -20,
        }}
    >
        <Card spread flex style={{ width: '100%', height: Dimensions.get('window').height - (Platform.OS == 'web' ? 80 : 0), minHeight: Dimensions.get('window').height - (Platform.OS == 'web' ? 80 : 0), margin: Platform.OS == 'web' ? 0 : 20 }}>
            <View style={{
                height: Dimensions.get('window').height - (Platform.OS == 'web' ? 80 : 30),
            }}>
                <View flex-6 padding-2 margin-10 marginB-0 style={{ borderRadius: 10, borderBottomEndRadius: 0, borderBottomStartRadius: 0 }}>
                    {formObject.table_id ? <View marginB-10>
                        {/* <CustomSelect
                            left={true}
                            placeholder="Select Table" value={formObject.table_id} options={response?.Tables || []}
                            onChange={(value) => {
                                setFormObject({ table_id: value });
                            }}
                            customSelectItem={(props) => {
                                return <>
                                    <View row centerV spread>
                                        <Text>{props.label}</Text>
                                        <View marginL-5>
                                            <Text tabHeading>{props.area?.name}</Text>
                                        </View>
                                    </View>
                                </>
                            }}
                        /> */}
                        <Text darkCardTitle>
                            Table: {response?.Tables.find((table) => table._id == formObject.table_id)?.name}
                        </Text>
                    </View> : null}
                    <View spread row>
                        <Text h1>Order Summary</Text>

                        {Platform.OS != 'web' && <TouchableOpacity onPress={() => {
                            setShowSummary(false);
                        }}>
                            <View row centerV>
                                <View marginR-5>
                                    <Icon name="arrow-back-outline" width={16} height={16} fill={"black"} />
                                </View>
                                <Text>
                                    Back
                                </Text>
                            </View>
                        </TouchableOpacity>}
                    </View>

                    <ScrollView left style={{
                        width: '100%',
                    }}
                    >
                        {[...(formObject.line_items || [])].map((orderItem, index) => {
                            return <View key={index.toString()} row spread centerV flex={Platform.OS == 'web'} style={{ width: '100%' }}>
                                <View>
                                    <View row>
                                        <TouchableOpacity onPress={() => {
                                            setShowEditItemModal(orderItem.item_id);
                                        }}>
                                            <View row centerV>
                                                <Text darkCardTitle
                                                    style={{
                                                        color: 'black',
                                                    }}
                                                >
                                                    {orderItem.item?.name}
                                                </Text>
                                                <Icon name="edit-outline" width={16} height={16} fill={'black'} />
                                            </View>
                                        </TouchableOpacity>

                                        <TouchableOpacity style={{
                                            marginRight: 10,
                                            backgroundColor: 'rgb(247, 250, 255)',
                                            paddingHorizontal: 6,
                                            paddingVertical: 0,
                                            borderRadius: 4,
                                            borderWidth: 1,
                                            borderColor: '#2062cf5e'
                                        }} onPress={() => {
                                            setShowItemNotesModal(orderItem.item_id);
                                        }}>
                                            <View row centerV>
                                                <Text darkCardTitle
                                                    style={{
                                                        color: 'rgb(41, 69, 156)',
                                                    }}
                                                >
                                                    {"Notes"}
                                                </Text>
                                            </View>
                                        </TouchableOpacity>

                                        {orderItem.item?.add_ons && orderItem.item?.add_ons.length > 0 && <TouchableOpacity style={{
                                            marginRight: 10,
                                            backgroundColor: 'rgb(247, 250, 255)',
                                            paddingHorizontal: 6,
                                            paddingVertical: 0,
                                            borderRadius: 4,
                                            borderWidth: 1,
                                            borderColor: '#2062cf5e'
                                        }} onPress={() => {
                                            setShowAddOnsModal(orderItem.item_id);
                                        }}>
                                            <View row centerV>
                                                <Text darkCardTitle
                                                    style={{
                                                        color: 'rgb(41, 69, 156)',
                                                    }}
                                                >
                                                    {"Add Ons"}
                                                </Text>
                                            </View>
                                        </TouchableOpacity>}
                                    </View>

                                    {showAddOnsModal == orderItem.item_id && <CustomModal
                                        width={Platform.OS == 'web' ? '30%' : '100%'}
                                        title="" visible={true} onClose={() => {
                                            setShowAddOnsModal(null);
                                        }} drawer={false}>
                                        <View>
                                            <View marginB-10>
                                                {Label({ label: "Select Addons" })}
                                                {orderItem.item?.add_ons && orderItem.item?.add_ons.length > 0 && orderItem.item?.add_ons.map((add_on) => {
                                                    return <View key={add_on._id} row centerV>
                                                        <CustomCheckbox
                                                            value={orderItem.add_ons_selected && orderItem.add_ons_selected.find((add_on_selected) => add_on_selected._id == add_on._id)}
                                                            onChange={(value) => {
                                                                const newOrderItems = [...formObject.line_items];
                                                                const index = newOrderItems.findIndex((item) => item.item_id == orderItem.item_id);
                                                                let original_unit_price = orderItem.item_price;

                                                                if (value) {
                                                                    newOrderItems[index].add_ons_selected = [...(newOrderItems[index].add_ons_selected || []), add_on];
                                                                    original_unit_price += add_on.price;
                                                                } else {
                                                                    newOrderItems[index].add_ons_selected = newOrderItems[index].add_ons_selected.filter((id) => id._id != add_on._id);
                                                                    original_unit_price -= add_on.price;
                                                                }

                                                                newOrderItems[index].item_price = original_unit_price;

                                                                setFormObject({ line_items: newOrderItems });

                                                                invoiceItemAdjustment(getFormObject(), setFormObject, setArrValue, response)
                                                            }}
                                                        />

                                                        <View marginL-5>
                                                            <Text darkCardTitle style={{ color: 'black', }}>
                                                                {add_on.name}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                })}
                                            </View>

                                            <PrimaryButton label={"Close"} onPress={() => {
                                                setShowAddOnsModal(null);
                                            }} />
                                        </View>
                                    </CustomModal>}


                                    {showItemNotesModal == orderItem.item_id && <CustomModal
                                        width={Platform.OS == 'web' ? '30%' : '100%'}
                                        title="" visible={true} onClose={() => {
                                            setShowItemNotesModal(null);
                                        }} drawer={false}>
                                        <View>
                                            <View marginB-10>
                                                {Label({ label: "Notes" })}
                                                <CustomTextField
                                                    value={orderItem.notes}
                                                    onChange={(value) => {
                                                        const newOrderItems = [...formObject.line_items];
                                                        const index = newOrderItems.findIndex((item) => item.item_id == orderItem.item_id);
                                                        newOrderItems[index].notes = value;
                                                        setFormObject({ line_items: newOrderItems });
                                                    }}
                                                />
                                            </View>

                                            <PrimaryButton label={"Close"} onPress={() => {
                                                setShowItemNotesModal(null);
                                            }} />
                                        </View>
                                    </CustomModal>}

                                    {showEditItemModal == orderItem.item_id && <CustomModal
                                        width={Platform.OS == 'web' ? '30%' : '100%'}
                                        title="" visible={true} onClose={() => {
                                            //setShowEditItemModal(null);
                                        }} drawer={false}>
                                        <View>
                                            <View marginB-10>
                                                {Label({ label: "Unit Price" })}
                                                <CustomNumberInput
                                                    value={orderItem.item_price}
                                                    onChange={(value) => {
                                                        const newOrderItems = [...formObject.line_items];
                                                        const index = newOrderItems.findIndex((item) => item.item_id == orderItem.item_id);
                                                        newOrderItems[index].item_price = value;
                                                        setFormObject({ line_items: newOrderItems });
                                                    }}
                                                />
                                            </View>

                                            <View marginB-30>
                                                {Label({ label: "Quantity" })}
                                                <CustomNumberInput
                                                    value={orderItem.quantity}
                                                    onChange={(value) => {
                                                        const newOrderItems = [...formObject.line_items];
                                                        const index = newOrderItems.findIndex((item) => item.item_id == orderItem.item_id);
                                                        newOrderItems[index].quantity = value;
                                                        setFormObject({ line_items: newOrderItems });
                                                    }}
                                                />
                                            </View>


                                            <PrimaryButton label={"Save and Close"} onPress={() => {
                                                setShowEditItemModal(null);
                                            }} />
                                        </View>
                                    </CustomModal>}

                                    <View row marginT-4>
                                        <Text
                                            style={{
                                                color: 'black',
                                            }}
                                        >
                                            {orderItem.item_price} x {orderItem.quantity} =
                                        </Text>
                                        <View marginL-5>
                                            <Text darkCardTitle style={{ color: 'rgb(77, 129, 241)', }}>
                                                ₹ {orderItem.item_price * orderItem.quantity}
                                            </Text>
                                        </View>
                                    </View>
                                    {orderItem.serial_numbers && orderItem.serial_numbers.length > 0 && <View row marginT-4>
                                        <Text
                                            style={{
                                                color: 'black',
                                            }}
                                        >
                                            Serial Numbers:
                                        </Text>
                                    </View>}

                                    {orderItem.serial_numbers && orderItem.serial_numbers.map((serial_number) => <View marginL-5 key={serial_number}>
                                        <Text darkCardTitle style={{ color: 'rgb(77, 129, 241)', }}>
                                            {serial_number}
                                        </Text>
                                    </View>)}
                                </View>
                                {Platform.OS == 'web' && <View row centerV>
                                    <TouchableOpacity onPress={() => {
                                        setShowTakeAwayQuantityModal(orderItem.item_id);
                                        setTakeAwayQuantity(orderItem.take_away_quantity);
                                    }}>
                                        <View>
                                            <Image source={{
                                                uri: 'https://cdn-icons-png.flaticon.com/512/3081/3081385.png',
                                            }}
                                                style={{
                                                    width: 20,
                                                    height: 20,
                                                }}
                                            />
                                        </View>
                                    </TouchableOpacity>

                                    {showTakeAwayQuantityModal == orderItem.item_id && <CustomModal
                                        width={Platform.OS == 'web' ? '50%' : '100%'}
                                        title="" visible={true} onClose={() => {
                                            setShowTakeAwayQuantityModal(null);
                                            setTakeAwayQuantity(0);
                                        }} drawer={false}>
                                        <View style={{
                                            padding: 20,
                                        }}>
                                            <View marginB-10>
                                                {Label({ label: "Enter Quantity" })}
                                                <CustomNumberInput
                                                    value={takeAwayQuantity}
                                                    onChange={(value) => {
                                                        setTakeAwayQuantity(value);
                                                    }}
                                                />
                                            </View>

                                            <PrimaryButton label={"Save"} onPress={() => {
                                                setShowTakeAwayQuantityModal(null);
                                                const newOrderItems = [...formObject.line_items];
                                                const index = newOrderItems.findIndex((item) => item.item_id == orderItem.item_id);
                                                newOrderItems[index].take_away_quantity = takeAwayQuantity;
                                                setFormObject({ line_items: newOrderItems });
                                                setTakeAwayQuantity(0);
                                            }} />
                                        </View>
                                    </CustomModal>}

                                    <TouchableOpacity onPress={() => {
                                        deleteProduct(orderItem)
                                    }}
                                        style={{
                                            padding: 10,
                                            backgroundColor: 'white',
                                            borderRadius: 10,
                                            margin: 10,
                                        }}
                                    >
                                        <Icon name="minus-circle-outline" width={16} height={16} fill={'darkred'} />
                                    </TouchableOpacity>
                                </View>}
                            </View>
                        })}
                    </ScrollView>
                </View>

                <View flex-7 style={{ width: '100%' }} padding-20 bottom>
                    <View backgroundColor="rgb(246, 246, 246)" padding-15 marginB-10 style={{ borderRadius: 10 }}>
                        <View marginB-10 spread row>
                            <Text darkCardTitle>
                                Sub Total
                            </Text>
                            <Text darkCardTitle>
                                ₹ {formObject.sub_total || 0}
                            </Text>
                        </View>
                        {/* <View marginB-10 spread row>
                                <Text darkCardTitle>
                                    Discount
                                </Text>
                                <Text darkCardTitle>
                                    ₹ {formObject.discount_amount || 0}
                                </Text>
                            </View> */}

                        <View>
                            {/* <SummaryItem label="Discount Percentage" keyName="discount_percentage"
                                    value={formObject.discount_percentage}
                                    setFn={setFormObject}
                                    onChange={(value) => {
                                        const sub_total = formObject.sub_total;
                                        const newDiscountAmount = RND(sub_total * (value / 100));
                                        setFormObject({ discount_amount: newDiscountAmount });
                                        invoiceItemAdjustment(getFormObject(), setFormObject, setArrValue, response)
                                    }} />

                                <SummaryItem
                                    label="Discount"
                                    keyName="discount_amount"
                                    value={formObject.discount_amount}
                                    setFn={setFormObject} onChange={(value) => {
                                        setFormObject({
                                            discount_percentage: null,
                                            discount_amount: value ? RND(value) : 0
                                        });
                                        invoiceItemAdjustment(getFormObject(), setFormObject, setArrValue, response)
                                    }} /> */}
                        </View>

                        {Platform.OS == 'web' ? <View row spread>
                            <View marginB-10 style={{
                                width: '48%'
                            }}>
                                <Text darkCardTitle>
                                    Discount %
                                </Text>
                                <View marginT-5>
                                    <CustomNumberInput
                                        value={formObject.discount_percentage}
                                        onChange={(value) => {
                                            const sub_total = formObject.sub_total;
                                            let newDiscountAmount = RND(sub_total * (value / 100));
                                            let newDiscountPercentage = value;

                                            if (newDiscountAmount < 0) {
                                                newDiscountAmount = 0;
                                                newDiscountPercentage = 0;
                                            }

                                            setFormObject({
                                                discount_amount: newDiscountAmount,
                                                discount_percentage: newDiscountPercentage,
                                            });
                                            invoiceItemAdjustment(getFormObject(), setFormObject, setArrValue, response)
                                        }}
                                    />
                                </View>
                            </View>

                            <View marginB-10 style={{
                                width: '48%'
                            }}>
                                <Text darkCardTitle>
                                    Discount Amount
                                </Text>
                                <View marginT-5>
                                    <CustomNumberInput
                                        value={formObject.discount_amount}
                                        onChange={(value) => {
                                            if (value < 0) {
                                                value = 0;
                                            }

                                            setFormObject({
                                                discount_percentage: null,
                                                discount_amount: value ? RND(value) : 0
                                            });
                                            invoiceItemAdjustment(getFormObject(), setFormObject, setArrValue, response)
                                        }}
                                    />
                                </View>
                            </View>
                        </View> : <View >
                            <View centerV row spread marginB-10 style={{
                                width: '100%'
                            }}>
                                <Text darkCardTitle>
                                    Discount %
                                </Text>
                                <View right style={{
                                    width: '50%',
                                }}>
                                    <View style={{
                                        borderBottomWidth: 1,
                                        borderBottomColor: 'gainsboro',
                                        width: '100%'
                                    }}>
                                        <TextField
                                            value={formObject.discount_percentage ? formObject.discount_percentage.toString() : formObject.discount_percentage === 0 ? '0' : null}
                                            onChangeText={(value) => {
                                                value = +value;
                                                const sub_total = formObject.sub_total;
                                                let newDiscountAmount = RND(sub_total * (value / 100));
                                                let newDiscountPercentage = value;

                                                if (newDiscountAmount < 0) {
                                                    newDiscountAmount = 0;
                                                    newDiscountPercentage = 0;
                                                }

                                                setFormObject({
                                                    discount_amount: newDiscountAmount,
                                                    discount_percentage: newDiscountPercentage,
                                                });
                                                invoiceItemAdjustment(getFormObject(), setFormObject, setArrValue, response)
                                            }}
                                            containerStyle={{
                                                width: '100%',
                                            }}
                                            keyboardType="numeric"
                                            textAlign="right"
                                            placeholder=""
                                        />
                                    </View>
                                </View>
                            </View>

                            <View centerV row spread marginB-10 style={{
                                width: '100%'
                            }}>
                                <Text darkCardTitle>
                                    Discount Amount
                                </Text>
                                <View right style={{
                                    width: '50%',
                                }}>
                                    <View style={{
                                        borderBottomWidth: 1,
                                        borderBottomColor: 'gainsboro',
                                        width: '100%'
                                    }}>
                                        <TextField
                                            value={formObject.discount_amount ? formObject.discount_amount.toString() : formObject.discount_amount === 0 ? '0' : null}
                                            onChangeText={(value) => {
                                                value = +value;
                                                if (value < 0) {
                                                    value = 0;
                                                }

                                                setFormObject({
                                                    discount_percentage: null,
                                                    discount_amount: value ? RND(value) : 0
                                                });

                                                invoiceItemAdjustment(getFormObject(), setFormObject, setArrValue, response)
                                            }}
                                            containerStyle={{
                                                width: '100%',
                                            }}
                                            keyboardType="numeric"
                                            textAlign="right"
                                            placeholder=""
                                        />
                                    </View>
                                </View>
                            </View>
                        </View>}

                        {/* {formObject.tax_map ? Object.keys(formObject.tax_map).map((key, index) => {
                                return <View key={index.toString()} row spread marginB-10>
                                    <View><Text darkCardTitle>{key}</Text></View>
                                    <View><Text darkCardTitle>{RND(formObject.tax_map[key] || 0)}</Text></View>
                                </View>
                            }) : null} */}

                        {/* just show one Taxes label with sum of taxes */}

                        <View marginB-10 spread row>
                            <Text darkCardTitle>
                                Taxes
                            </Text>
                            <Text darkCardTitle>
                                ₹ {formObject.tax_map ? RND(Object.values(formObject.tax_map).reduce((a, b) => a + b, 0)) : 0}
                            </Text>
                        </View>

                        <View marginB-10 spread row>
                            <Text darkCardTitle>
                                Total
                            </Text>
                            <Text darkCardTitle>
                                ₹ {formObject.total || 0}
                            </Text>
                        </View>
                    </View>

                    <View row centerV marginB-10>
                        <View flex row centerV>
                            <CustomCheckbox
                                value={sendInKot}
                                onChange={(value) => {
                                    setSendInKot(value);
                                }}
                            />
                            <View marginT-4 style={{
                                marginTop: 2,
                                marginLeft: 5,
                            }}>
                                {Label({ label: "Send to Kitchen" })}
                            </View>
                        </View>


                        {sendInKot && response?.kot_template ? <View flex>
                            <SecondaryButton label={"Print KOT"} onPress={async () => {
                                await PrintKOT(getFormObject, response)

                                await saveFn({
                                    print: false,
                                    goBackAfterSave: false,
                                    clearOrder: false
                                })
                            }} />
                        </View> : null}
                    </View>

                    <View row spread>
                        {!formObject.table_id ? <></> : <View flex marginR-2>
                            <DangerButton label={"On Hold"} onPress={async () => {
                                setFormObject({
                                    status: "Pending"
                                });

                                await saveFn({
                                    print: false,
                                    goBackAfterSave: true
                                })
                            }} />
                        </View>}

                        <View flex marginR-2 marginL-2>
                            <SecondaryButton label={"Print"} onPress={async () => {
                                await saveFn({
                                    print: true,
                                    goBackAfterSave: false,
                                    clearOrder: false
                                })
                            }} />
                        </View>

                        <View flex marginL-2>
                            <PrimaryButton label={"Payment"} onPress={() => {
                                setPaymentModalVisible(true)
                            }} />
                        </View>
                    </View>
                </View>
            </View>
        </Card>
    </Col>;

    return (
        <View flex>
            <View backgroundColor="#f9f9f9b5"
                style={{
                    padding: Platform.OS == 'web' ? 20 : 6
                }}
            >
                <Row>
                    <Col width={Platform.OS == 'web' ? "75%" : '100%'} >
                        <Card style={{ width: '100%' }}>
                            <View margin-10 marginB-00 style={{ borderRadius: 10, borderBottomEndRadius: 0, borderBottomStartRadius: 0 }}>
                                <View spread={Platform.OS == 'web'} row={Platform.OS == 'web'}>
                                    <View flex={Platform.OS == 'web'} row={Platform.OS == 'web'}>
                                        {Platform.OS == 'web' && <View marginR-5>
                                            <DangerButton style={{
                                                height: 40,
                                            }} onPress={() => {
                                                goBack();
                                            }}>
                                                <View row centerV>
                                                    <Icon name="arrow-back-outline" width={16} height={16} fill={"#b60505"} />
                                                    <Text marginL-5 style={{
                                                        color: '#b60505',
                                                        fontFamily: 'SourceSansProSemiBold'
                                                    }}>
                                                        Go Back
                                                    </Text>
                                                </View>
                                            </DangerButton>
                                        </View>}
                                        <View style={{
                                            width: Platform.OS == 'web' ? 200 : '100%',
                                            marginRight: Platform.OS == 'web' ? 10 : 0,
                                        }}>
                                            <CustomSelect
                                                left={true}
                                                placeholder="Search Customer"
                                                value={formObject.contact_id}
                                                options={response?.Contacts || []}
                                                creatableAction={(props) => {
                                                    const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, phone: props.search } : {}) };

                                                    OpenModalCustom({ newModule: "Contacts", field_key: 'contact_id', params });
                                                }}
                                                customSelectItem={CustomSelectItemForContacts}
                                                otherSearchKeys={['phone', 'email']}
                                                onChange={(value) => {
                                                    const contact = response.Contacts.find((contact) => contact._id === value);
                                                    const gst_numbers = contact.gst_numbers || [];
                                                    const addresses = contact.addresses || [];
                                                    const primary_gst_number = gst_numbers.find((gst_number) => gst_number.primary == "Yes");
                                                    const primary_billing_address = addresses.find((address) => address.primary_billing == "Yes");
                                                    const primary_shipping_address = addresses.find((address) => address.primary_shipping == "Yes");
                                                    setFormObject({
                                                        contact_id: value,
                                                        contact: contact,
                                                        addresses: addresses,
                                                        gst_treatment: contact.gst_treatment,
                                                        gst_number: primary_gst_number ? primary_gst_number.gst_number : '',
                                                        billing_address_line_1: primary_billing_address ? primary_billing_address.address_line_1 : '',
                                                        billing_address_line_2: primary_billing_address ? primary_billing_address.address_line_2 : '',
                                                        billing_city: primary_billing_address ? primary_billing_address.city : '',
                                                        billing_state: primary_billing_address ? primary_billing_address.state : '',
                                                        billing_pincode: primary_billing_address ? primary_billing_address.pincode : '',
                                                        billing_country: primary_billing_address ? primary_billing_address.country : '',
                                                        shipping_address_line_1: primary_shipping_address ? primary_shipping_address.address_line_1 : '',
                                                        shipping_address_line_2: primary_shipping_address ? primary_shipping_address.address_line_2 : '',
                                                        shipping_city: primary_shipping_address ? primary_shipping_address.city : '',
                                                        shipping_state: primary_shipping_address ? primary_shipping_address.state : '',
                                                        shipping_pincode: primary_shipping_address ? primary_shipping_address.pincode : '',
                                                        shipping_country: primary_shipping_address ? primary_shipping_address.country : '',
                                                    });
                                                }}
                                            />
                                        </View>
                                        {Platform.OS == 'web' && <View flex={Platform.OS == 'web'} style={{
                                            width: Platform.OS == 'web' ? 200 : '100%',
                                            marginRight: Platform.OS == 'web' ? 10 : 0,
                                        }}>
                                            <CustomTextField placeholder="Search or Scan Products" value={search} onChange={(e) => {
                                                setSearch(e);
                                            }} />
                                            <View style={{
                                                position: 'absolute',
                                                right: 12,
                                                top: 12
                                            }}>
                                                <Icon name="search-outline" width={16} height={16} fill={'gray'} />
                                            </View>
                                        </View>}

                                        {Platform.OS != 'web' && <View>
                                            {/* <CustomTextFieldWithScanner placeholder="Search Products" value={search} onChange={(e) => {
                                                setSearch(e);
                                            }} /> */}
                                            <View row centerV flexG>
                                                {Platform.OS != 'web' && <View marginR-10>
                                                    <TouchableOpacity
                                                        style={{
                                                            padding: 10,
                                                            width: 16 + 20,
                                                        }}
                                                        onPress={() => {
                                                            setShowScanner(true);
                                                        }}>
                                                        <Image source={{
                                                            uri: 'https://cdn-icons-png.flaticon.com/128/2169/2169993.png',
                                                        }} style={{ width: 16, height: 16 }} />
                                                    </TouchableOpacity>
                                                </View>}

                                                {Platform.OS != 'web' && <View marginR-0>
                                                    <View style={{ width: 1, height: 16, backgroundColor: '#e0e0e0', marginRight: 8 }} />
                                                </View>}

                                                {Platform.OS != 'web' && <View centerV flexG style={{
                                                    position: 'relative',
                                                }}>
                                                    <TextField
                                                        style={{
                                                            height: 42,
                                                            // backgroundColor: 'red'
                                                        }}
                                                        placeholder={"Search Products"}
                                                        value={search}
                                                        onChange={(e) => {
                                                            // onChange(e.nativeEvent.text);
                                                            setSearch(e.nativeEvent.text);
                                                        }} />

                                                    {search ? <View style={{
                                                        position: 'absolute',
                                                        right: 0,
                                                    }}>
                                                        <TouchableOpacity
                                                            style={{
                                                                padding: 10,
                                                                width: 16 + 20,
                                                                borderRadius: 500,
                                                                height: 16 + 20,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                            onPress={() => {
                                                                onChange("");
                                                            }}>
                                                            <Icon name='backspace-outline' width={16} height={16} fill={'#53535f'} />
                                                        </TouchableOpacity>
                                                    </View> : null}

                                                </View>}
                                            </View>


                                            <CustomModal title="Scan" visible={showScanner} onClose={() => {
                                                setShowScanner(false);
                                            }}>
                                                <MainScannerScreenComponent onClose={(data) => {
                                                    if (data === null) {
                                                        setShowScanner(false);
                                                    } else {
                                                        setSearch(data);
                                                    }
                                                }} />
                                            </CustomModal>
                                        </View>}
                                        {/* <View style={{
                                            width: Platform.OS == 'web' ? 200 : '100%',
                                        }}>
                                            <CustomSelect
                                                value={formObject.location_id}
                                                placeholder="Select Location"
                                                options={response?.Locations || []}
                                                onChange={(value) => {
                                                    setFormObject({
                                                        location_id: value,
                                                    });
                                                    setGlobalData({
                                                        location_id: value,
                                                    })
                                                }}
                                            />
                                        </View> */}
                                    </View>

                                    {/* <View marginL-5>
                                        <SecondaryButton label={"Pending Orders"} onPress={() => {
                                            // navigationFn("on-hold-orders", {});
                                        }} />
                                    </View> */}
                                </View>

                            </View>

                            <View row style={{
                                flexWrap: 'wrap',
                            }}>
                                <TouchableOpacity onPress={() => {
                                    setSelectedCategory(null);
                                }}
                                    style={{
                                        padding: 10,
                                        backgroundColor: selectedCategory == null ? 'rgb(77, 129, 241)' : 'white',
                                        borderRadius: 10,
                                        margin: 10,
                                    }}
                                >
                                    <Text
                                        style={{
                                            color: selectedCategory == null ? 'white' : 'black',
                                        }}
                                    >
                                        All Menu
                                    </Text>
                                </TouchableOpacity>

                                {categories.map((category, index) => {
                                    return <TouchableOpacity key={index.toString()} onPress={() => {
                                        setSelectedCategory(category._id);
                                    }}
                                        style={{
                                            padding: 10,
                                            backgroundColor: selectedCategory == category._id ? 'rgb(77, 129, 241)' : 'white',
                                            borderRadius: 10,
                                            margin: 10,
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: selectedCategory == category._id ? 'white' : 'black',
                                            }}
                                        >
                                            {category.name}
                                        </Text>
                                    </TouchableOpacity>
                                })}
                            </View>
                        </Card>
                        <View style={{
                            height: Dimensions.get('window').height - (Platform.OS == 'web' ? 200 : 260),
                            width: '100%',
                            marginTop: 10,
                            position: 'relative',
                        }}>
                            <FlashList
                                data={filteredProducts}
                                numColumns={Platform.OS == 'web' ? 4 : 2}
                                estimatedItemSize={170}
                                renderItem={({ item }) => (
                                    <View
                                        style={{
                                            width: '96%',
                                            paddingHorizontal: 5,
                                            marginBottom: 18,
                                            position: 'relative',
                                        }}
                                        containerStyle={{
                                            width: '100%',
                                        }}
                                    // onPress={() => {
                                    //     if (item.track_serial_number) {
                                    //         if (Platform.OS == 'web') {
                                    //             message.info("Please scan the serial number for items with serial number tracking.");
                                    //         } else {
                                    //             Alert.alert("Please scan the serial number for items with serial number tracking.");
                                    //         }
                                    //     } else {
                                    //         addProduct(item)
                                    //     }
                                    // }}
                                    // onLongPress={() => {

                                    // }}
                                    >
                                        <Card onPress={() => {
                                            if (!item.active) {
                                                return;
                                            }
                                            if (item.track_serial_number) {
                                                if (Platform.OS == 'web') {
                                                    message.info("Please scan the serial number for items with serial number tracking.");
                                                } else {
                                                    Alert.alert("Please scan the serial number for items with serial number tracking.");
                                                }
                                            } else {
                                                addProduct(item)
                                            }
                                        }} flex style={{ width: '100%', padding: 10, position: 'relative' }}>
                                            {item.image_urls[0] ? <Image source={{
                                                uri: item.image_urls[0].url
                                            }} style={{ width: '100%', height: 120, resizeMode: 'cover', borderRadius: 10, marginBottom: 10 }} /> : <Image source={{
                                                uri: "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg"
                                            }} style={{ width: '100%', height: 120, resizeMode: 'cover', borderRadius: 10, marginBottom: 10 }} />}

                                            <View row spread>
                                                <View flex>
                                                    <Text style={{ color: 'black' }}>
                                                        {item.name}
                                                    </Text>
                                                    <Text style={{ color: 'gray', fontSize: 10, letterSpacing: 0.3 }}>
                                                        {item.sku}
                                                    </Text>
                                                </View>

                                                <TouchableOpacity onPress={() => {
                                                    const newProducts = [...products];
                                                    const index = newProducts.findIndex((product) => product._id == item._id);
                                                    const newValue = !newProducts[index].active;
                                                    newProducts[index].active = newValue
                                                    setProducts(newProducts);

                                                    api.customRoute('Invoices/action', {
                                                        action: 'markActiveInactive',
                                                        _id: item._id,
                                                        active: newValue,
                                                    }).then((response) => {

                                                    })
                                                }}>
                                                    <View>
                                                        <CustomSwitch
                                                            value={item.active}
                                                            onChange={(value) => {
                                                                // const newProducts = [...products];
                                                                // const index = newProducts.findIndex((product) => product._id == item._id);
                                                                // newProducts[index].active = value;
                                                                // setProducts(newProducts);

                                                                // api.customRoute('Invoices/action', {
                                                                //     action: 'markActiveInactive',
                                                                //     _id: item._id,
                                                                //     active: value,
                                                                // }).then((response) => {

                                                                // })
                                                            }}
                                                        />
                                                    </View>
                                                </TouchableOpacity>
                                            </View>
                                            <View row spread marginT-10 centerV>
                                                <View right>
                                                    <Text darkCardTitle style={{ color: 'rgb(77, 129, 241)', }}>
                                                        ₹ {item.sales_rate}
                                                    </Text>
                                                </View>


                                                <View right row centerV style={{
                                                    opacity: item.active ? 1 : 0.5,
                                                }}>
                                                    <TouchableOpacity onPress={() => {
                                                        if (!item.active) {
                                                            return;
                                                        }
                                                        const orderItem = formObject.line_items.find((orderItem) => orderItem.item_id == item._id);
                                                        if (orderItem && orderItem.quantity > 0) {
                                                            deleteProduct(orderItem)
                                                        }
                                                    }}
                                                        style={{
                                                            padding: 4,
                                                            backgroundColor: 'white',
                                                            borderRadius: 10,
                                                            zIndex: 1000,
                                                        }}
                                                    >
                                                        <Icon name="minus-circle-outline" width={20} height={20} fill={'darkred'} />
                                                    </TouchableOpacity>

                                                    <View center style={{
                                                        width: 30,
                                                    }}>
                                                        <Text center>
                                                            {item.quantity || 0}
                                                        </Text>
                                                    </View>

                                                    <TouchableOpacity onPress={() => {
                                                        if (!item.active) {
                                                            return;
                                                        }
                                                        if (item.track_serial_number) {
                                                            if (Platform.OS == 'web') {
                                                                message.info("Please scan the serial number for items with serial number tracking.");
                                                            } else {
                                                                Alert.alert("Please scan the serial number for items with serial number tracking.");
                                                            }
                                                        } else {
                                                            addProduct(item)
                                                        }
                                                    }}
                                                        style={{
                                                            padding: 4,
                                                            backgroundColor: 'white',
                                                            borderRadius: 10,
                                                        }}
                                                    >
                                                        <Icon name="plus-circle-outline" width={20} height={20} fill={'darkgreen'} />
                                                    </TouchableOpacity>
                                                </View>
                                            </View>
                                        </Card>
                                        <View style={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                        }}>
                                            {item.quantity > 0 && <View backgroundColor="#dc3f4d" center style={{
                                                borderRadius: 100,
                                                width: 20,
                                                height: 20,
                                            }}>
                                                <Text style={{
                                                    color: 'white',
                                                    lineHeight: 20,
                                                }}>
                                                    {"" + item.quantity || "" + 0}
                                                </Text>
                                            </View>}
                                        </View>
                                    </View>
                                )}
                                keyExtractor={(item) => item._id}
                            />
                            {(Platform.OS != 'web' && formObject.line_items && formObject.line_items.length > 0) && <View paddingV-4 row style={{
                                width: '100%',
                            }}>
                                <SecondaryButton
                                    style={{
                                        height: 40,
                                        flex: 1,
                                        marginRight: 4,
                                    }}
                                    label={"Summary/Discount"} onPress={() => {
                                        if (!formObject.contact_id) {
                                            if (Platform.OS === 'web') {
                                                message.info("Please select customer");
                                            } else {
                                                Alert.alert("Please select customer");
                                            }
                                            return;
                                        }
                                        setShowSummary(true);
                                    }} />
                                <SecondaryButton
                                    style={{
                                        height: 40,
                                        flex: 1,
                                        marginLeft: 4,
                                    }}
                                    label={"Record Payment2"} onPress={() => {
                                        if (!formObject.contact_id) {
                                            if (Platform.OS === 'web') {
                                                message.info("Please select customer");
                                            } else {
                                                Alert.alert("Please select customer");
                                            }
                                            return;
                                        }
                                        setPaymentModalVisible(true)
                                    }} />
                            </View>}

                            <CustomModal title="" visible={showSummary} onClose={() => {
                                setShowSummary(false)
                            }}>
                                <View>
                                    {Summary}
                                </View>
                            </CustomModal>
                        </View>
                    </Col>

                    {Platform.OS == 'web' && Summary}
                </Row>
            </View>
            <CustomModal title="" visible={paymentModalVisible} onClose={() => {
                setPaymentModalVisible(false)
            }} drawer={false} width={700}>
                <ScrollView style={{
                    padding: Platform.OS == 'web' ? 0 : 20
                }}>
                    <View marginB-20 row spread centerV>
                        <Text h1>Order Summary</Text>

                        {Platform.OS != 'web' && <TouchableOpacity onPress={() => {
                            setPaymentModalVisible(false)
                        }}>
                            <View row centerV>
                                <View marginR-5>
                                    <Icon name="arrow-back-outline" width={16} height={16} fill={"black"} />
                                </View>
                                <Text>
                                    Back
                                </Text>
                            </View>
                        </TouchableOpacity>}
                    </View>
                    <View marginB-5 row spread>
                        <View>
                            <Text darkCardTitle style={{ lineHeight: 38 }}>Invoice Total</Text>
                        </View>
                        <View>
                            <Text darkCardTitle style={{ lineHeight: 38 }}>₹ {formObject.total || 0}</Text>
                        </View>
                    </View>

                    <View marginB-5 centerV row spread>
                        <View>
                            <Text darkCardTitle>Cash</Text>
                        </View>
                        <View right style={{
                            width: '50%'
                        }}>
                            <View style={{
                                borderBottomWidth: 1,
                                borderBottomColor: 'gainsboro',
                            }}>
                                <CustomNumberInput right placeholder="Amount Received"
                                    value={cash_received}
                                    onChange={(e) => {
                                        setCashReceived(e);
                                    }}
                                    onFocus={(value) => {
                                        if (!value) {
                                            setCashReceived(+formObject.total - +(bankAccounts.reduce((a, b) => a + (b.amount_received || 0), 0)));
                                        }
                                    }} />
                            </View>
                        </View>
                    </View>
                    {bankAccounts.map((account) => <View key={account._id} marginB-5 centerV row spread>
                        <View>
                            <Text darkCardTitle>{account.name}</Text>
                        </View>
                        <View right style={{
                            width: '50%'
                        }}>
                            <View style={{
                                borderBottomWidth: 1,
                                borderBottomColor: 'gainsboro',
                            }}>
                                <CustomNumberInput right placeholder="Amount Received"
                                    value={account.amount_received}
                                    onChange={(e) => {
                                        const newBankAccounts = [...bankAccounts];
                                        const index = newBankAccounts.findIndex((cashAccount) => cashAccount._id == account._id);
                                        newBankAccounts[index].amount_received = e;
                                        setBankAccounts(newBankAccounts);
                                    }}
                                    onFocus={(value) => {
                                        if (!value) {
                                            const newBankAccounts = [...bankAccounts];
                                            const index = newBankAccounts.findIndex((cashAccount) => cashAccount._id == account._id);
                                            newBankAccounts[index].amount_received = +(formObject.total) - +(+(cash_received) + bankAccounts.reduce((a, b) => a + (b.amount_received || 0), 0));

                                            setBankAccounts(newBankAccounts);
                                        }
                                    }} />
                            </View>
                        </View>
                    </View>)}
                    <View marginB-5 centerV row spread>
                        <View>
                            <Text style={{ lineHeight: 38 }} darkCardTitle>Cash To Return</Text>
                        </View>
                        <View>
                            <Text darkCardTitle style={{ lineHeight: 38 }}>₹ {(+cash_received + +(bankAccounts.reduce((a, b) => a + (b.amount_received || 0), 0))) > +(formObject.total) ? +(+cash_received + +(bankAccounts.reduce((a, b) => a + (b.amount_received || 0), 0))) - formObject.total : 0}</Text>
                        </View>
                    </View>

                    <View marginB-20>
                        {Heading({ heading: "Actual Invoice Payment" })}
                    </View>

                    <View marginB-5 centerV row spread>
                        <View>
                            <Text darkCardTitle style={{ lineHeight: 38 }}>Cash</Text>
                        </View>
                        <View>
                            <Text darkCardTitle style={{
                                lineHeight: 38,
                                textAlign: 'right',
                            }}>₹ {actualCash || 0}</Text>
                        </View>
                    </View>

                    {bankAccounts.map((account) => <View key={account._id} marginB-5 centerV row spread>
                        <View>
                            <Text darkCardTitle>{account.name}</Text>
                        </View>
                        <View>
                            <Text darkCardTitle style={{
                                lineHeight: 38,
                                textAlign: 'right',
                            }}>₹ {account.amount_received || 0}</Text>
                        </View>
                    </View>)}

                    <View row={Platform.OS == 'web'} marginT-15 marginB-5>
                        <PrimaryButton label={"Save and Print"} onPress={async () => {
                            const obj = getFormObject();
                            if (formObject.table_id) {
                                setFormObject({
                                    status: "Delivered",
                                    savePaymentDetails: true,
                                });
                            } else {
                                setFormObject({
                                    status: obj.status == "Delivered" ? "Delivered" : "Pending",
                                    savePaymentDetails: true,
                                });
                            }

                            await saveFn({
                                print: true,
                                goBackAfterSave: true,
                                delivered: true
                            })
                        }} disabled={saving} />

                        <SecondaryButton style={{
                            marginLeft: Platform.OS == 'web' ? 10 : 0,
                            marginTop: Platform.OS == 'web' ? 0 : 10,
                        }} label={"Save and Print with KOT"} onPress={async () => {
                            await PrintKOT(getFormObject, response)

                            const obj = getFormObject();
                            if (formObject.table_id) {
                                setFormObject({
                                    status: "Delivered",
                                    savePaymentDetails: true,
                                });
                            } else {
                                setFormObject({
                                    status: obj.status == "Delivered" ? "Delivered" : "Pending",
                                    savePaymentDetails: true,
                                });
                            }

                            await saveFn({
                                print: true,
                                goBackAfterSave: true,
                                delivered: true
                            })
                        }} disabled={saving} />


                        <SecondaryButton style={{
                            marginLeft: Platform.OS == 'web' ? 10 : 0,
                            marginTop: Platform.OS == 'web' ? 0 : 10,
                        }} label={"Save without Print"} onPress={async () => {
                            const obj = getFormObject();
                            if (formObject.table_id) {
                                setFormObject({
                                    status: "Delivered",
                                    savePaymentDetails: true,
                                });
                            } else {
                                setFormObject({
                                    status: obj.status == "Delivered" ? "Delivered" : "Pending",
                                    savePaymentDetails: true,
                                });
                            }

                            await saveFn({
                                print: false,
                                goBackAfterSave: true,
                                delivered: true
                            })
                        }} disabled={saving} />
                        <DangerButton style={{
                            marginLeft: Platform.OS == 'web' ? 10 : 0,
                            marginTop: Platform.OS == 'web' ? 0 : 10,
                        }} label={"Cancel"} onPress={() => {
                            setPaymentModalVisible(false)
                        }} disabled={saving} />

                    </View>
                </ScrollView>
            </CustomModal>

            <CustomModal title="" visible={showPDF} onClose={() => {
                setShowPDF(false)
                setTransaction(null)
                getModuleCreateData({ disable_auto_line_item: true });
            }} drawer={false}
                width={'40%'}
            >
                <View style={[{
                    height: Dimensions.get('window').height - 100,
                }, Platform.OS != 'web' && {
                    width: Dimensions.get('window').width,
                }]}>

                    <View padding-15 margin-10 marginB-0 style={{ borderRadius: 10, borderBottomEndRadius: 0, borderBottomStartRadius: 0 }}>
                        <View spread row>
                            {Platform.OS != 'web' && <Text h1>Invoice Preview</Text>}
                            {Platform.OS != 'web' && <TouchableOpacity onPress={() => {
                                setShowPDF(false);
                                setTransaction(null)
                                getModuleCreateData({ disable_auto_line_item: true });
                            }}>
                                <View row centerV>
                                    <View marginR-5>
                                        <Icon name="arrow-back-outline" width={16} height={16} fill={"black"} />
                                    </View>
                                    <Text>
                                        Back
                                    </Text>
                                </View>
                            </TouchableOpacity>}
                        </View>
                    </View>
                    {transaction ? <TransactionPDFPreview moduleName={moduleName} obj={{
                        ...transaction,
                    }} /> : null}
                </View>
            </CustomModal>
        </View >
    )

    function deleteProduct(orderItem) {
        let newOrderItems = formObject.line_items.map((item) => {
            if (item.item_id == orderItem.item_id) {

                if (item.serial_numbers && item.serial_numbers.length > 0) {
                    item.serial_numbers.pop()
                    return {
                        ...item,
                        quantity: item.quantity - 1,
                        serial_numbers: item.serial_numbers,
                    }
                } else {
                    return {
                        ...item,
                        quantity: item.quantity - 1,
                    }
                }
            }

            return item
        })

        newOrderItems = newOrderItems.filter((orderItem) => {
            return orderItem.quantity > 0
        })

        setFormObject({
            line_items: newOrderItems,
        })

        const newProducts = [...products]
        const index = newProducts.findIndex((product) => product._id == orderItem.item_id)
        newProducts[index].quantity = newProducts[index].quantity - 1
        setProducts(newProducts)
    }

    function addProduct(item, serial_number, qt = 0) {

        let found = false
        const obj = getFormObject()
        if (obj && obj.line_items) {
            let item_quantity = 0

            let newOrderItems = obj.line_items.map((orderItem) => {
                if (orderItem.item_id == item._id) {
                    found = true

                    if (serial_number && item.track_serial_number && serial_number.length > 0) {
                        let serial_numbers = [...(orderItem.serial_numbers || []), serial_number];
                        serial_numbers = [...new Set(serial_numbers)];

                        item_quantity = serial_numbers.length;

                        return {
                            ...orderItem,
                            quantity: serial_numbers.length,
                            item_price: orderItem.item_price,
                            serial_numbers: serial_numbers,
                        }
                    } else {
                        item_quantity = qt ? +qt : (orderItem.quantity + 1);


                        return {
                            ...orderItem,
                            quantity: item_quantity,
                            item_price: orderItem.item_price,
                        }
                    }
                }

                return orderItem
            })

            if (!found) {
                item_quantity = qt ? +qt : 1

                const TaxGroups = response.TaxGroups || []
                const tax_group = TaxGroups.find((tax_group) => tax_group._id === item.tax_group_id)

                const itemMap = {
                    "item": item,
                    "item_id": item._id,
                    "item_name": item.name,
                    "hsn_or_sac": item.hsn_or_sac,
                    "description": item.description,
                    "account_id": item.sales_account_id,
                    "track_inventory": item.track_inventory,
                    "track_serial_number": item.track_serial_number,
                    "track_batch_number": item.track_batch_number,
                    "usage_unit": item.usage_unit,
                    "tax_group_id": item.tax_group_id,
                    "tax_group": tax_group,
                    "quantity": 1,
                    "item_price": item.sales_rate || 0,
                    "item_gross_total": item.sales_rate || 0,
                    "item_total": item.sales_rate || 0,
                    "discount_percentage": 0,
                    "discount_amount": 0,
                    "status": moduleName == 'Invoices' ? 'Pending' : moduleName == 'Bills' ? 'Pending' : 'Pending',
                    "serial_numbers": serial_number ? [serial_number] : [],
                    "add_ons_selected": [],
                }

                newOrderItems.push(itemMap)
            }

            setFormObject({
                line_items: newOrderItems,
            })

            const filteredProducts = products.map((product) => {
                if (product._id == item._id) {
                    return {
                        ...product,
                        quantity: item_quantity,
                    }
                }

                return product
            })

            setProducts(filteredProducts)
        }
    }

    async function saveFn({ print, goBackAfterSave = false, clearOrder = true, delivered = false }) {
        const newFormObject = { ...getFormObject() }

        if (!newFormObject.contact_id) {
            if (Platform.OS === 'web') {
                message.info("Please select customer");
            } else {
                Alert.alert("Please select customer");
            }
            return;
        }

        // if no items added then return
        if (!newFormObject.line_items || newFormObject.line_items.length == 0) {
            if (Platform.OS === 'web') {
                message.info("Please add items to the order");
            } else {
                Alert.alert("Please add items to the order");
            }
            return;
        }

        let payment_details = []

        if (cash_received > 0) {
            payment_details.push({
                account_id: cashAccountId,
                amount: cash_received - ((cash_received + bankAccounts.reduce((a, b) => a + (b.amount_received || 0), 0)) > formObject.total ? (cash_received + bankAccounts.reduce((a, b) => a + (b.amount_received || 0), 0)) - formObject.total : 0),
            })
        }

        for (let i = 0; i < bankAccounts.length; i++) {
            const account = bankAccounts[i]
            if (account.amount_received > 0) {
                payment_details.push({
                    account_id: account._id,
                    amount: account.amount_received,
                })
            }
        }

        if (delivered) {
            const retailCustomer = response?.Contacts.find((contact) => contact.label == "WALK-IN");

            if (retailCustomer && newFormObject.contact_id == retailCustomer._id) {
                const totalPaidAmount = cash_received + bankAccounts.reduce((a, b) => a + (b.amount_received || 0), 0);

                if (totalPaidAmount < newFormObject.total) {
                    if (Platform.OS === 'web') {
                        message.info("Please record the payment for the order.");
                    } else {
                        Alert.alert("Please record the payment for the order.");
                    }
                    return;
                }
            }
        }

        newFormObject.payment_details = payment_details;
        newFormObject.generatePdf = false;

        if (sendInKot) {
            const line_items = newFormObject.line_items.map((line_item) => {
                if (line_item.kot_status != "Ready" && line_item.kot_status != "Delivered") {
                    line_item.kot_status = "Pending";
                }

                if (!line_item.kot_sent_quantity || line_item.kot_sent_quantity != line_item.quantity) {
                    line_item.kot_sent_quantity = line_item.quantity;
                }

                return line_item;
            })

            newFormObject.line_items = line_items;
        }

        newFormObject.require_admin_approval = false;

        setSaving(true);

        let res = null;

        let queryParams = {};

        if (Platform.OS === 'web') {
            const params = window.location.search.substring(1).split('&')
            params.map((param) => {
                const [key, value] = param.split('=')
                if (key) {
                    queryParams[key] = value;
                }
            })
        } else {
            queryParams = props.route.params || {};
        }

        if (delivered) {
            if (newFormObject.table_id) {
                newFormObject.status = "Delivered";
            } else {
                newFormObject.status = newFormObject.status == "Delivered" ? "Delivered" : "Pending";
            }
        }

        if (queryParams.invoice_id || invoiceId) {
            res = await api.update(moduleName, newFormObject, queryParams.invoice_id || invoiceId);
        } else {
            res = await api.save(moduleName, newFormObject);
        }

        if (res.message) {
            if (Platform.OS === 'web') {
                message.info(res.message);
            } else {
                Alert.alert(res.message);
            }

            setSaving(false);

            if (res.success) {
                if (print) {
                    const { data } = await api.getSingle(moduleName, {
                        _id: res.data._id,
                        platform: Platform.OS
                    });

                    const html = data?.html;

                    if (Platform.OS === 'web') {
                        const printWindow = window.open('', '_blank', 'width=600,height=600');

                        // Write the invoice HTML content to the new window
                        printWindow.document.write(`
                            <style>
                                @page {
                                    size: 65mm 297mm;
                                    margin: 0;
                                }
                                body {
                                    width: 65mm;
                                    margin: 0;
                                    font-family: Arial, sans-serif;
                                    font-size: 13px;
                                    font-weight: bold;
                                    line-height: 1.4;
                                }
                                @media print {
                                    html, body {
                                        width: 65mm;
                                    }
                                }
                                img  {
                                    width: 100%;
                                }
                            </style>
                            ${html}
                            <script>
                                window.onload = function() {
                                    window.print();
                                }
                            </script>
                        `);

                        // Close the document writing
                        printWindow.document.close();
                    } else {
                        await Print.printAsync({
                            html,
                            width: 612,
                            height: 100,
                        });
                    }
                }

                setCashReceived(0);

                const newBankAccounts = [...bankAccounts];

                for (let i = 0; i < newBankAccounts.length; i++) {
                    newBankAccounts[i].amount_received = 0;
                }

                setBankAccounts(newBankAccounts);
                setPaymentModalVisible(false);
                setShowSummary(false);

                if (goBackAfterSave) {
                    goBack();
                }

                if (clearOrder) {
                    getModuleCreateData({ disable_auto_line_item: true });
                } else {
                    setFormObject({
                        _id: res.data._id,
                    });

                    setInvoiceId(res.data._id);
                    getModuleCreateData({ disable_auto_line_item: true }, res.data._id);
                }
            }
        } else {
            setSaving(false);
            if (Platform.OS === 'web') {
                message.error("Something went wrong!");
            } else {
                Alert.alert("Something went wrong!");
            }
        }
    }
})

export default PosScreen

async function PrintKOT(getFormObject, response) {
    const obj = getFormObject()
    console.log(obj)
    let oldTransaction = { ...obj }
    let order_type = 'Take Away'

    if (oldTransaction.table_id) {
        const table = response.Tables.find((table) => table._id == oldTransaction.table_id)
        order_type = 'TABLE - ' + table.name
    }

    const kot_token = await AsyncStorage.getItem('kot_token')
    let new_token = kot_token ? (+kot_token + 1) : (1)
    await AsyncStorage.setItem('kot_token', new_token.toString())

    let html = response.kot_template.html_template
    //replace =&gt; with =>
    html = html.replace(/=&gt;/g, '=>')

    const org_details = response.org_preferences
    const contact = oldTransaction.contact

    oldTransaction.date = moment(oldTransaction.date).format('MMM DD, YYYY')
    oldTransaction.date_time = moment(oldTransaction.date_time).format('MMM DD, YYYY hh:mm A')
    oldTransaction.discount_amount = oldTransaction.discount_amount || 0

    const taxes = []

    const tax_map = oldTransaction.tax_map

    for (const tax_name in tax_map) {
        taxes.push({
            name: tax_name,
            amount: tax_map[tax_name]
        })
    }

    const discount_amoount = oldTransaction.discount_amount
    const sub_total = oldTransaction.sub_total

    oldTransaction.discount_percentage = RND(discount_amoount / sub_total * 100)

    const total_quantity = oldTransaction.line_items.reduce((total, item) => total + item.quantity, 0)

    oldTransaction.notes = oldTransaction.notes || ''
    oldTransaction.terms_conditions = oldTransaction.terms_conditions || ''

    oldTransaction.address = ''

    if (!contact?.phone) {
        contact.phone = ''
    }

    oldTransaction.address = oldTransaction.billing_addr_line_1 || ''
    oldTransaction.address += oldTransaction.billing_addr_line_2 ? '<br>' + oldTransaction.billing_addr_line_2 : ''
    oldTransaction.address += (oldTransaction.billing_city || oldTransaction.billing_state || oldTransaction.billing_zip) ? '<br>' : ''
    oldTransaction.address += oldTransaction.billing_city ? oldTransaction.billing_city : ''
    oldTransaction.address += oldTransaction.billing_city && (oldTransaction.billing_state || oldTransaction.billing_zip) ? ',' : ''
    oldTransaction.address += oldTransaction.billing_state ? oldTransaction.billing_state : ''
    oldTransaction.address += oldTransaction.billing_zip ? oldTransaction.billing_zip : ''
    oldTransaction.address += oldTransaction.billing_country ? '<br>' + oldTransaction.country : ''

    let new_line_items = []

    for (let i = 0; i < oldTransaction.line_items.length; i++) {
        const item = { ...oldTransaction.line_items[i] }
        if (item.kot_sent_quantity == item.quantity) {
            continue
        } else {
            item.quantity = item.quantity - (item.kot_sent_quantity || 0)
            item.item_gross_total = item.quantity * item.item_price
            new_line_items.push(item)
        }
    }

    oldTransaction.line_items = new_line_items

    const payment_details = []

    const data = {
        ...oldTransaction,
        org_details,
        total_quantity,
        taxes,
        payment_details,
        kot_token: new_token,
        order_type: order_type,
    }

    const Sqrl = require('squirrelly')

    const compiledHtml = Sqrl.render(html, data)

    const printWindow = window.open('', '_blank', 'width=' + screen.availWidth + ',height=' + screen.availHeight)

    // Write the invoice HTML content to the new window
    printWindow.document.write(`
                                    <style>
                                        @page {
                                            size: 65mm 200mm;
                                            margin: 0;
                                        }
                                        body {
                                            width: 65mm;
                                            margin: 0;
                                            font-family: Arial, sans-serif;
                                            font-size: 13px;
                                            font-weight: bold;
                                            line-height: 1.4;
                                        }
                                        @media print {
                                            html, body {
                                                width: 65mm;
                                            }
                                        }
                                        img  {
                                            width: 100%;
                                        }
                                    </style>
                                    ${compiledHtml}
                                    <script>
                                        window.onload = function() {
                                            window.print();
                                        }
                                    </script>
                                `)

    printWindow.document.close()
}
